import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

// the css
import "../styles/style.css"

// title differs dependent on current page
function dynTitle() {
  const path = typeof window !== "undefined" ? window.location.pathname : "/"
  if (path === "/")
    return "ALPINRECHT - alpinrecht.eu / alpenrecht.de / alpin-recht.de"
  if (path === "/impressum")
    return "ALPINRECHT - Anwaltskanzlei Friedrich Schweikert"
  return "ALPINRECHT"
}

// title differs dependent on current page
const MenuEntry = props => (
  <span className="item">
    <Link activeClassName={"active"} {...props} />
  </span>
)

export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        <title>{dynTitle()}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="google-site-verification"
          content="Fk8YX_HS441Ix8mbooOCMoigyo7F-X34I3cBOZTAmAo"
        />
        <meta name="audience" content=" Alle" />
        <meta name="author" content="Rechtsanwalt Friedrich Schweikert" />
        <meta name="copyright" content="Rechtsanwalt Friedrich Schweikert" />
        <meta
          name="description"
          content="Alpinrecht - Kanzlei Schweikert, Rechtsanwalt und Strafverteidiger - Telefon 08061 9386895"
        />
        <meta
          name="keywords"
          content="Alpinrecht, Alpenrecht, Bergrecht, Höhlenrecht, Bergunfall, Skiunfall, Rechtsanwalt, Anwalt, Strafverteidiger, Verteidiger, Strafrecht, Zivilrecht, Oberbayern, Allgäu, Bayern, Alpen, Haftungsrecht, Vertragsrecht, Nebenklage, Beistand, Opferschutz, Unfall, Bergführer, Alpenverein, Bergrettung, Almhütte, Recht, Gesetz, FIS, Regeln"
        />
        <meta name="page-topic" content="Recht" />
        <meta name="page-type" content="Anzeige" />
        <meta name="publisher" content="Kanzlei Schweikert" />
        <meta name="revisit-after" content="30 days" />
        <meta name="robots" content=" INDEX,FOLLOW" />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.png" />
      </Helmet>
      <div className="page rem-of-13">
        <div className="header">
          <div className="banner">
            alpinrecht.eu
          </div>
          <div className="navi">
            <MenuEntry to="/">Willkommen</MenuEntry>
            <MenuEntry to="/impressum">Impressum</MenuEntry>
          </div>
        </div>
        <div className="main">
        {children}
        </div>
        <div className="footer">
          <Link
            to="/impressum"
            style={{ color: "#FFFFFF", fontWeight: "normal" }}
          >
            Kanzlei Schweikert, Lagerhausstr.12, Postfach 1248
            in 83036 Bad Aibling, Tel +49 (0) 8061 9386895, Fax
            +49 (0) 8061 9386894
          </Link>
        </div>
      </div>
    </>
  )
}
